<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { A11y, Autoplay, Pagination } from 'swiper';
import { easeCubicIn } from '@/constants';
import 'swiper/scss';

const { t, locale } = useI18n();

const langKey = computed(() => locale.value);

const paginationText = computed(() => [
	t('ratingSpace.simple.title'),
	t('ratingSpace.cawpile.title'),
	t('ratingSpace.custom.title'),
]);

const pagination = computed(() => ({
	clickable: true,
	renderBullet: (index: number, className: string) => {
		const text = paginationText.value[index];
		return `<span class="${className}">${text}</span>`;
	},
}));
</script>

<template>
	<section class="container mys:80">
		<div class="content-container mye:80">
			<h2 class="index-title index-title--center">
				<span class="index-title__sm index-title--secondary">{{ $t('index.features.title') }}</span>
			</h2>
		</div>

		<div class="feature-section splitting-observer">
			<div class="feature-section__body feature-section__body--end">
				<div class="feature-section__content">
					<h3 class="index-title">
						<span class="index-title__md splitting splitting--line-up" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section1.title') }}</span>
						<span class="aria:sr">{{ $t('index.features.section1.title') }}</span>
					</h3>
					<p>
						<span class="splitting splitting--line-up splitting-delay--1000" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section1.description') }}</span>
						<span class="aria:sr">{{ $t('index.features.section1.description') }}</span>
					</p>
					<div class="d:flex ai:center gap:8">
						<span
							:initial="{ opacity: 0, y: 40 }"
							:variants="{ visibleOnce: { opacity: 1, y: 0, transition: { type: 'keyframes', duration: 1200, ease: [easeCubicIn] } } }"
							:delay="1600"
							v-motion
						>
							<Button :label="$t('index.features.section1.button1')" :link-to="{ name: 'app.aboutSimpleRating' }" />
						</span>
						<span
							:initial="{ opacity: 0, y: 40 }"
							:variants="{ visibleOnce: { opacity: 1, y: 0, transition: { type: 'keyframes', duration: 1200, ease: [easeCubicIn] } } }"
							:delay="1700"
							v-motion
						>
							<Button :label="$t('index.features.section1.button2')" :link-to="{ name: 'app.aboutCawpileRating' }" severity="primary" />
						</span>
					</div>
				</div>
			</div>
			<div class="feature-section__media feature-section__media--slider">
				<ClientOnly :key="langKey">
					<Swiper
						class="swiper--index-featuring"
						:modules="[A11y, Pagination, Autoplay]"
						:ally="true"
						:slides-per-view="1"
						:space-between="20"
						:pagination="pagination"
						:speed="2000"
						:autoplay="{
							delay: 6000,
							disableOnInteraction: false,
						}"
					>
						<SwiperSlide>
							<FirebaseImg name="index-rating-simple.webp" :alt="$t('aria.ratingSimpleImage')" />
						</SwiperSlide>
						<SwiperSlide>
							<FirebaseImg name="index-rating-cawpile.webp" :alt="$t('aria.ratingCawpileImage')" />
						</SwiperSlide>
						<SwiperSlide>
							<FirebaseImg name="index-rating-custom.webp" :alt="$t('aria.ratingCustomImage')" />
						</SwiperSlide>
					</Swiper>
				</ClientOnly>
			</div>
		</div>

		<div class="feature-section splitting-observer">
			<div class="feature-section__body">
				<div class="feature-section__content">
					<h3 class="index-title">
						<span class="index-title__md splitting splitting--line-up" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section2.title') }}</span>
						<span class="aria:sr">{{ $t('index.features.section2.title') }}</span>
					</h3>
					<p>
						<span class="splitting splitting--line-up splitting-delay--1000" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section2.description') }}</span>
						<span class="aria:sr">{{ $t('index.features.section2.description') }}</span>
					</p>
				</div>
			</div>
			<div class="feature-section__media">
				<div class="feature-mask-wrapper">
					<div class="feature-mask feature-mask--1">
						<FirebaseImg class="feature-mask__image" name="index-currently-reading.webp" :alt="$t('aria.currentlyReadingImage')" />
					</div>
				</div>
			</div>
		</div>

		<div class="feature-section splitting-observer">
			<div class="feature-section__body feature-section__body--end">
				<div class="feature-section__content">
					<h3 class="index-title">
						<span class="index-title__md splitting splitting--line-up" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section3.title') }}</span>
						<span class="aria:sr">{{ $t('index.features.section3.title') }}</span>
					</h3>
					<p>
						<span class="splitting splitting--line-up splitting-delay--1000" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section3.description') }}</span>
						<span class="aria:sr">{{ $t('index.features.section3.description') }}</span>
					</p>
				</div>
			</div>
			<div class="feature-section__media">
				<div class="feature-mask-wrapper">
					<div class="feature-mask feature-mask--2">
						<FirebaseImg class="feature-mask__image" name="index-challenges.webp" :alt="$t('aria.challengesImage')" />
					</div>
				</div>
			</div>
		</div>

		<div class="feature-section splitting-observer">
			<div class="feature-section__body">
				<div class="feature-section__content">
					<h3 class="index-title">
						<span class="index-title__md splitting splitting--line-up" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section4.title') }}</span>
						<span class="aria:sr">{{ $t('index.features.section4.title') }}</span>
					</h3>
					<p>
						<span class="splitting splitting--line-up splitting-delay--1000" data-splitting-type="lines" aria-hidden="true">{{ $t('index.features.section4.description') }}</span>
						<span class="aria:sr">{{ $t('index.features.section4.description') }}</span>
					</p>
					<span
						:initial="{ opacity: 0, y: 40 }"
						:variants="{ visibleOnce: { opacity: 1, y: 0, transition: { type: 'keyframes', duration: 1200, ease: [easeCubicIn] } } }"
						:delay="1600"
						v-motion
					>
						<Button :label="$t('index.features.section4.button')" :link-to="{ name: 'app.search' }" severity="primary" />
					</span>
				</div>
			</div>
			<div class="feature-section__media">
				<div class="feature-mask-wrapper">
					<div class="feature-mask feature-mask--1">
						<FirebaseImg class="feature-mask__image" name="index-search.webp" :alt="$t('aria.searchImage')" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
