<script setup lang="ts">
import type { Swiper as SwiperClass } from 'swiper';
import { A11y, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { easeBounce, easeCubicIn } from '@/constants';
import 'swiper/scss';
import 'swiper/scss/effect-fade';

const portfolioList = [
	{ id: 0, theme: null, name: 'Jane Doe', username: 'janeD', social: ['fb', 'in', 'x', 'yt', 'gr'], description: 'Devoted reader with a penchant for mystery and thriller novels. Enjoys unraveling complex plots and sharing insightful reviews.', own: 189, rate: 92, wishlist: 49, dnf: 3 },
	{ id: 1, theme: 'shadow', name: 'John Smith', username: 'smithyJ', social: ['x', 'in', 'gr'], description: 'Avid reader and reviewer of contemporary literature. Passionate about uncovering hidden gems in the world of books.', own: 67, rate: 65, wishlist: 3, dnf: 2 },
	{ id: 2, theme: 'premium', name: 'Judy Roe', username: 'judyyy', social: ['fb', 'gr'], description: 'Historical fiction aficionado and aspiring writer. Loves diving into the past through literature and sharing her thoughts online.', own: 352, rate: 297, wishlist: 17, dnf: 29 },
];

// Swiper
const easeOutQuad = (t: number): number => t * (2 - t);

const animateNumber = (element: Element, start: number, end: number, duration: number, delay: number = 0) => {
	let startTimestamp: number | null = null;

	element.textContent = start.toString();

	const step = (timestamp: number) => {
		if (!startTimestamp)
			startTimestamp = timestamp;
		const progressTime = timestamp - startTimestamp - delay;
		if (progressTime < 0) {
			window.requestAnimationFrame(step);
			return;
		}
		const progress = Math.min(progressTime / duration, 1);
		element.textContent = Math.floor(easeOutQuad(progress) * (end - start) + start).toString();
		if (progress < 1)
			window.requestAnimationFrame(step);
	};

	setTimeout(() => {
		window.requestAnimationFrame(step);
	}, delay);
};

const updateActiveSlide = (swiper: SwiperClass) => {
	swiper.slides.forEach((slide: Element) => {
		slide.querySelectorAll('.splitting').forEach((el: Element) => {
			el.classList.remove('splitting--active');
		});
	});

	const activeSlideElement = swiper.slides[swiper.activeIndex] as Element;
	activeSlideElement.querySelectorAll('.splitting').forEach((el: Element) => {
		el.classList.add('splitting--active');
	});
	const numbersToAnimate = activeSlideElement.querySelectorAll('.portfolio-summary__value');
	numbersToAnimate.forEach((numElement, index) => {
		const targetValue = parseInt(numElement.textContent || '0', 10);
		const animateDelay = 1000 + (100 * index);
		animateNumber(numElement, 0, targetValue, 2400, animateDelay);
	});
};

const onSwiperInit = (swiper: SwiperClass) => {
	updateActiveSlide(swiper);
};
</script>

<template>
	<section class="container splitting-observer">
		<div class="content-container mye:80">
			<h2 class="index-title index-title--center">
				<span class="index-title__sm splitting splitting--line-up" data-splitting-type="lines" aria-hidden="true">{{ $t('index.portfolio.title.primary') }}</span>
				<span class="aria:sr">{{ $t('index.portfolio.title.primary') }}</span>

				<span class="index-title__md index-title--secondary splitting splitting--char-down" data-splitting-type="chars" aria-hidden="true">{{ $t('index.portfolio.title.secondary') }}</span>
				<span class="aria:sr">{{ $t('index.portfolio.title.secondary') }}</span>

				<Shape
					name="arrow-1"
					label="arrow"
					severity="secondary"
					animated
					:initial="{ opacity: 0, x: -40 }"
					:enter="{ opacity: 1, x: 0, transition: { type: 'keyframes', duration: 3600, ease: [easeBounce] } }"
					:delay="800"
					v-motion
				/>
				<Shape
					name="underline-9"
					label="underline"
					severity="tertiary"
					animated
					:initial="{ opacity: 0 }"
					:enter="{ opacity: 1, transition: { type: 'keyframes', duration: 800, ease: [easeCubicIn] } }"
					:delay="800"
					v-motion
				/>
				<Shape
					name="misc-1"
					label="star"
					severity="primary"
					animated
					:initial="{ opacity: 0, scale: 0 }"
					:enter="{ opacity: 1, scale: 1, transition: { type: 'keyframes', duration: 4000, ease: [easeBounce] } }"
					:delay="1200"
					v-motion
				/>
				<Shape
					name="misc-2"
					label="star-2"
					severity="primary"
					animated
					:initial="{ opacity: 0, scale: 0 }"
					:enter="{ opacity: 1, scale: 1, transition: { type: 'keyframes', duration: 4000, ease: [easeBounce] } }"
					:delay="1300"
					v-motion
				/>
			</h2>

			<p class="index-subtitle splitting splitting--line-up splitting-delay--800" data-splitting-type="lines" aria-hidden="true">
				{{ $t('index.portfolio.subtitle') }}
			</p>
			<span class="aria:sr">{{ $t('index.portfolio.subtitle') }}</span>

			<span
				:initial="{ opacity: 0, y: 40 }"
				:variants="{ visibleOnce: { opacity: 1, y: 0, transition: { type: 'keyframes', duration: 1200, ease: [easeCubicIn] } } }"
				:delay="1600"
				v-motion
			>
				<Button
					:label="$t('index.portfolio.button')"
					:link-to="{ name: 'app.register' }"
					severity="primary"
					gradient
					shadow
				/>
			</span>
		</div>
	</section>

	<section class="container container--full">
		<ClientOnly>
			<Swiper
				class="swiper--index-portfolio"
				:modules="[A11y, Autoplay, EffectFade]"
				:ally="true"
				:loop="true"
				:slides-per-view="1"
				:speed="2000"
				:allow-touch-move="false"
				:autoplay="{
					delay: 8000,
					disableOnInteraction: false,
					pauseOnMouseEnter: false,
				}"
				effect="fade"
				@init="onSwiperInit"
				@slide-change-transition-start="updateActiveSlide"
			>
				<SwiperSlide v-for="portfolio in portfolioList" :key="portfolio.id">
					<div class="portfolio-wrapper portfolio-index portfolio-theme" :class="{ [`portfolio-theme--${portfolio.theme}`]: portfolio.theme }">
						<div class="portfolio">
							<div class="portfolio__header">
								<span class="user-frame">
									<span class="user-frame__frame-wrapper">
										<img :src="`/images/index/portfolio/frame-${portfolio.id}.webp`" class="user-frame__frame" aria-hidden="true" />
									</span>
									<span class="user-frame__image-wrapper">
										<img :src="`/images/index/portfolio/user-${portfolio.id}.webp`" class="user-frame__image" />
									</span>
								</span>
							</div>
							<div class="portfolio__body">
								<div class="portfolio-user">
									<h1 class="portfolio-user__title">
										<span class="splitting splitting--char-up splitting-delay--800" data-splitting-type="chars" aria-hidden="true">{{ portfolio.name }}</span>
									</h1>
									<span class="portfolio-user__username splitting splitting--char-down splitting-delay--1000" data-splitting-type="chars" aria-hidden="true">@{{ portfolio.username }}</span>
									<ul class="portfolio-user__social-list">
										<li class="portfolio-user__social-item" v-if="portfolio.social.includes('fb')">
											<span class="portfolio-user__social-link">
												<Icon name="social-facebook" />
											</span>
										</li>
										<li class="portfolio-user__social-item" v-if="portfolio.social.includes('in')">
											<span class="portfolio-user__social-link">
												<Icon name="social-instagram" />
											</span>
										</li>
										<li class="portfolio-user__social-item" v-if="portfolio.social.includes('x')">
											<span class="portfolio-user__social-link">
												<Icon name="social-twitter-x" />
											</span>
										</li>
										<li class="portfolio-user__social-item" v-if="portfolio.social.includes('yt')">
											<span class="portfolio-user__social-link">
												<Icon name="social-youtube" />
											</span>
										</li>
										<li class="portfolio-user__social-item" v-if="portfolio.social.includes('gr')">
											<span class="portfolio-user__social-link">
												<Icon name="social-goodreads" />
											</span>
										</li>
									</ul>
									<span class="portfolio-user__description splitting splitting--line-up splitting-delay--1600" data-splitting-type="lines" aria-hidden="true">
										{{ portfolio.description }}
									</span>
								</div>

								<div class="portfolio-summary">
									<div class="portfolio-summary__item portfolio-summary__item--own">
										<span class="portfolio-summary__value">{{ portfolio.own }}</span>
										<span class="portfolio-summary__name">{{ $t('props.booksOwned') }}</span>
									</div>
									<div class="portfolio-summary__item portfolio-summary__item--rate">
										<span class="portfolio-summary__value">{{ portfolio.rate }}</span>
										<span class="portfolio-summary__name">{{ $t('props.rated') }}</span>
									</div>
									<div class="portfolio-summary__item portfolio-summary__item--wishlist">
										<span class="portfolio-summary__value">{{ portfolio.wishlist }}</span>
										<span class="portfolio-summary__name">{{ $t('props.wishlisted') }}</span>
									</div>
									<div class="portfolio-summary__item portfolio-summary__item--dnf">
										<span class="portfolio-summary__value">{{ portfolio.dnf }}</span>
										<span class="portfolio-summary__name">{{ $t('props.dnfed') }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="portfolio-background">
							<picture aria-hidden="true">
								<source :srcset="`/images/index/portfolio/background-${portfolio.id}_2048x1024.webp`" media="(min-width:106.25em)" />
								<source :srcset="`/images/index/portfolio/background-${portfolio.id}_1920x960.webp`" media="(min-width:93.75em)" />
								<source :srcset="`/images/index/portfolio/background-${portfolio.id}_1460x840.webp`" media="(min-width:75em)" />
								<source :srcset="`/images/index/portfolio/background-${portfolio.id}_1080x720.webp`" media="(min-width:62em)" />
								<source :srcset="`/images/index/portfolio/background-${portfolio.id}_720x480.webp`" media="(min-width:48em)" />
								<source :srcset="`/images/index/portfolio/background-${portfolio.id}_538x538.webp`" />
								<img class="portfolio-background__image" :src="`/images/index/portfolio/background-${portfolio.id}.jpg`" />
							</picture>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</ClientOnly>
	</section>
</template>
