<template>
	<section>
		<div class="support-us">
			<div class="container">
				<h2 class="index-title index-title--center">
					<span class="index-title__sm">{{ $t('index.supportUs.title.primary') }}</span>
					<span class="index-title__md index-title--secondary">{{ $t('index.supportUs.title.secondary') }}</span>
				</h2>
			</div>

			<div class="container container--sm">
				<div class="support-us__main">
					<div class="support-us__start">
						<div class="support-us__content">
							<Icon name="heart" :size="48" />
							<p class="fs:18">
								{{ $t('index.supportUs.description') }}
							</p>
							<Button
								:label="$t('index.supportUs.button')"
								severity="primary"
								gradient
								link-to="https://www.patreon.com/readfolio"
								link-target="_blank"
							/>
						</div>
					</div>
					<div class="support-us__end">
						<div class="support-us__media">
							<FirebaseImg name="index-supporter.webp" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
