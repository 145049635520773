<script setup lang="ts">
const { isFirefox, isMobile } = useDevice();

// Hero
const imageX = ref(0);
const imageY = ref(0);
const defaultPosition = { x: 0, y: 0 };
let targetPosition = { ...defaultPosition };
const isMouseInside = ref(false);

const imageStyle = ref({
	transform: `translate(${defaultPosition.x}px, ${defaultPosition.y}px)`,
});

// Define strength values
const followStrength = !isFirefox ? 80 : 30;
const smoothStrength = !isFirefox ? 0.02 : 0.4;

const updateTargetPosition = (event: MouseEvent, el: HTMLElement) => {
	if (!el || !(el instanceof HTMLElement))
		return;

	const rect = el.getBoundingClientRect();
	const centerX = rect.width / 2;
	const centerY = rect.height / 2;
	targetPosition.x = (event.clientX - rect.left - centerX) / followStrength;
	targetPosition.y = (event.clientY - rect.top - centerY) / followStrength;
	isMouseInside.value = true;
};

const handleMouseMove = (event: MouseEvent, el: HTMLElement) => {
	updateTargetPosition(event, el);
};

const handleMouseEnter = (event: MouseEvent, el: HTMLElement) => {
	updateTargetPosition(event, el);
};

const handleMouseLeave = () => {
	isMouseInside.value = false;
	targetPosition = { ...defaultPosition };
};

let animationFrameId: number;

const animate = () => {
	const deltaX = Math.abs(targetPosition.x - imageX.value);
	const deltaY = Math.abs(targetPosition.y - imageY.value);

	if (deltaX > 0.1 || deltaY > 0.1 || isMouseInside.value) {
		imageX.value += (targetPosition.x - imageX.value) * smoothStrength;
		imageY.value += (targetPosition.y - imageY.value) * smoothStrength;
		imageStyle.value = {
			transform: `translate(${imageX.value}px, ${imageY.value}px)`,
		};
	}

	animationFrameId = requestAnimationFrame(animate);
};

const startAnimation = () => {
	animationFrameId = requestAnimationFrame(animate);
};

const stopAnimation = () => {
	cancelAnimationFrame(animationFrameId);
	imageX.value = 0;
	imageY.value = 0;
	imageStyle.value = {
		transform: 'translate(0px, 0px)',
	};
};

onMounted(() => {
	if (!isMobile)
		startAnimation();
});

onUnmounted(() => {
	stopAnimation();
});
</script>

<template>
	<div
		class="hero"
		ref="hero"
		@mousemove="event => handleMouseMove(event, $refs.hero as HTMLElement)"
		@mouseenter="event => handleMouseEnter(event, $refs.hero as HTMLElement)"
		@mouseleave="handleMouseLeave"
	>
		<div class="hero__container">

			<div class="hero-content splitting-now">
				<h1 class="hero-content__title">
					<span class="hero-content__title-primary splitting splitting--line-up splitting--active" data-splitting-type="lines" aria-hidden="true">{{ $t('index.hero.title.primary') }}</span>
					<span class="aria:sr">{{ $t('index.hero.title.primary') }}</span>
					<span class="hero-content__title-secondary splitting splitting--line-up splitting-delay--1000 splitting--active" data-splitting-type="lines" aria-hidden="true">{{ $t('index.hero.title.secondary') }}</span>
					<span class="aria:sr">{{ $t('index.hero.title.secondary') }}</span>
				</h1>
				<p class="hero-content__subtitle">
					<span class="splitting splitting--line-up splitting-delay--1000 splitting--active" data-splitting-type="lines" aria-hidden="true">{{ $t('index.hero.subtitle') }}</span>
					<span class="aria:sr">{{ $t('index.hero.subtitle') }}</span>
				</p>
			</div>

			<div class="hero-media">
				<div class="hero-mask">
					<div class="hero-shader">
						<picture class="hero-shader__picture">
							<source srcset="/images/index/index-hero_560x560.webp" media="(min-width:62em)" />
							<source srcset="/images/index/index-hero_480x480.webp" media="(min-width:48em)" />
							<source srcset="/images/index/index-hero_400x400.webp" />
							<img
								class="hero-shader__image"
								src="/images/index/index-hero.jpg"
								:alt="$t('aria.heroImage')"
								:style="imageStyle"
							/>
						</picture>
						<div class="hero-shader__specular">
							<div class="hero-shader__mask"></div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<style lang="scss">
@import '@/assets/scss/modules/_splitting';
</style>
