interface SplitResult {
	lines: Array<Array<Element>>
	el: HTMLElement
}

function applySplitting(target: string | NodeListOf<Element>, splitType: 'chars' | 'lines') {
	const targets = typeof target === 'string' ? document.querySelectorAll(target) : target;
	const splittingResult = Splitting({ target: targets, by: splitType });

	if (splitType === 'lines') {
		splittingResult.forEach((splitResult: SplitResult) => {
			const wrappedLines = splitResult.lines
				.map((wordsArr: Array<Element>) => `
					<span class="words">
						${wordsArr.map(word => `${word.outerHTML}<span class="whitespace"> </span>`).join('')}
					</span>`,
				).join('');
			splitResult.el.innerHTML = wrappedLines;
		});
	}
}

export default function initSplitting() {
	applySplitting('[data-splitting-type="chars"]', 'chars');
	applySplitting('[data-splitting-type="lines"]', 'lines');
}

export function splittingObserver() {
	const targets = document.querySelectorAll<HTMLElement>('.splitting-observer');
	const featureMask = document.querySelector<HTMLElement>('.feature-mask');

	const observerCallback: IntersectionObserverCallback = (entries, observer) => {
		entries.forEach((entry: IntersectionObserverEntry) => {
			if (entry.isIntersecting) {
				requestAnimationFrame(() => {
					entry.target.querySelectorAll<HTMLElement>('[data-splitting-type]').forEach((splitElement: HTMLElement) => {
						splitElement.classList.add('splitting--active');
					});

					if (featureMask) {
						const animation = entry.target.querySelector<HTMLElement>('.feature-mask');
						animation?.classList.add('feature-mask--active');
					}

					observer.unobserve(entry.target);
				});
			}
		});
	};

	const observer = new IntersectionObserver(observerCallback, { threshold: 0.4 });
	targets.forEach(target => observer.observe(target));
}
