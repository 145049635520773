<script setup lang="ts">
import { easeBounce, easeCubicIn } from '@/constants';

const { t } = useI18n();

const libraryFeaturesList = computed(() => [
	{ id: 0, icon: 'playlist-add', title: t('index.library.features.1.title'), description: t('index.library.features.1.description') },
	{ id: 1, icon: '100', title: t('index.library.features.2.title'), description: t('index.library.features.2.description') },
	{ id: 2, icon: 'trash', title: t('index.library.features.3.title'), description: t('index.library.features.3.description') },
	{ id: 3, icon: 'book', title: t('index.library.features.4.title'), description: t('index.library.features.4.description') },
	{ id: 4, icon: 'favorite-book', title: t('index.library.features.5.title'), description: t('index.library.features.5.description') },
	{ id: 5, icon: 'open-book', title: t('index.library.features.6.title'), description: t('index.library.features.6.description') },
	{ id: 6, icon: 'fast-arrow-right', title: t('index.library.features.7.title'), description: t('index.library.features.7.description') },
]);
</script>

<template>
	<section class="container">
		<div class="content-container mye:80">
			<div class="mye:120">
				<Shape
					name="arrow-11"
					label="arrow-separator"
					severity="secondary"
					static
					animated
					:initial="{ opacity: 0, y: 48 }"
					:variants="{ visibleOnce: { opacity: 1, y: 0, transition: { type: 'keyframes', duration: 1000, ease: [easeCubicIn] } } }"
					v-motion
				/>
			</div>
			<h2 class="index-title index-title--center gap:48 splitting-observer" aria-hidden="true">
				<span class="index-title__sm index-title--secondary splitting splitting--char-up splitting-delay-400" data-splitting-type="chars">{{ $t('index.library.title1') }}</span>
				<span class="index-title__md splitting splitting--line-up splitting-delay--600" data-splitting-type="lines" v-html="$t('index.library.title2')"></span>
				<span class="index-title__wrapper">
					<span class="index-title__lg index-title--secondary splitting splitting--char-up splitting-delay--1600" data-splitting-type="chars">{{ $t('index.library.title3') }}</span>
					<Shape
						name="arrow-6"
						label="arrow-2"
						severity="tertiary"
						animated
						:initial="{ opacity: 0, x: -24 }"
						:variants="{ visibleOnce: { opacity: 1, x: 0, transition: { type: 'keyframes', duration: 3000, ease: [easeBounce] } } }"
						:delay="2400"
						v-motion
					/>
					<Shape
						name="misc-7"
						label="heart"
						severity="error"
						animated
						:initial="{ opacity: 0, x: -24 }"
						:variants="{ visibleOnce: { opacity: 1, x: 0, transition: { type: 'keyframes', duration: 3000, ease: [easeBounce] } } }"
						:delay="2300"
						v-motion
					/>
					<Shape
						name="highlight-3"
						label="highlight"
						severity="secondary"
						animated
						:initial="{ opacity: 0, x: -24, y: -10 }"
						:variants="{ visibleOnce: { opacity: 1, x: 0, y: 0, transition: { type: 'keyframes', duration: 3000, ease: [easeBounce] } } }"
						:delay="2400"
						v-motion
					/>
					<Shape
						name="misc-1"
						label="star-3"
						severity="primary"
						animated
						:initial="{ opacity: 0, x: -10, y: 24 }"
						:variants="{ visibleOnce: { opacity: 1, x: 0, y: 0, transition: { type: 'keyframes', duration: 3000, ease: [easeBounce] } } }"
						:delay="2300"
						v-motion
					/>
					<Shape
						name="misc-3"
						label="star-4"
						severity="primary"
						animated
						:initial="{ opacity: 0, x: 24, y: -24 }"
						:variants="{ visibleOnce: { opacity: 1, x: 0, y: 0, transition: { type: 'keyframes', duration: 3000, ease: [easeBounce] } } }"
						:delay="2400"
						v-motion
					/>
				</span>
			</h2>
		</div>

		<div class="content-container">
			<div class="library-promo">
				<div
					class="library-promo__image-wrapper"
					:initial="{ opacity: 0, y: 24 }"
					:variants="{ visibleOnce: { opacity: 1, x: 0, y: 0, transition: { type: 'keyframes', duration: 3000, ease: [easeBounce] } } }"
					:delay="2200"
					v-motion
				>
					<picture aria-hidden="true">
						<source srcset="/images/index/library/index-my-library_1620x1694.webp" media="(min-width:93.75em)" />
						<source srcset="/images/index/library/index-my-library_1200x1574.webp" media="(min-width:62em)" />
						<source srcset="/images/index/library/index-my-library_640x1330.webp" />
						<img class="library-promo__image" src="/images/index/library/index-my-library_1620x1694.jpg" :alt="$t('aria.myLibraryImage')" />
					</picture>
				</div>
			</div>
		</div>

		<div class="content-container">
			<h2 class="index-title splitting-observer">
				<span class="index-title__sm index-title--secondary splitting splitting--char-up" data-splitting-type="chars" aria-hidden="true">{{ $t('index.library.title4') }}:</span>
				<span class="aria:sr">{{ $t('index.library.titleAria') }}:</span>
			</h2>
		</div>

		<div class="library-features">
			<ul class="library-features__list">
				<li
					class="library-features__item"
					:initial="{ opacity: 0, y: 48 }"
					:variants="{ visibleOnce: { opacity: 1, y: 0, transition: { type: 'keyframes', duration: 800, ease: [easeCubicIn] } } }"
					:delay="(150 * index) + 600"
					v-for="(item, index) in libraryFeaturesList"
					:key="item.id"
					v-motion
				>
					<Icon :name="item.icon" />
					<span class="library-features__title">{{ item.title }}</span>
					<span class="library-features__description">{{ item.description }}</span>
				</li>
			</ul>
		</div>
	</section>
</template>
