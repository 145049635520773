<script setup lang="ts">
import initSplitting, { splittingObserver } from '@/assets/ts/splitting';

definePageMeta({
	layout: 'default',
	name: 'app.index',
});

useSeoMeta({
	title: 'Readfol.io - The NEXT Platform for Avid Readers',
	ogTitle: 'Readfol.io -  The NEXT Platform for Avid Readers',
});

onMounted(() => {
	setTimeout(() => {
		initSplitting();
		splittingObserver();
	}, 1);
});
</script>

<template>
	<IndexHero />
	<IndexPortfolio />
	<IndexFeatures />
	<IndexLibrary />
	<IndexSupportUs />
</template>
